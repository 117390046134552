import React, { useCallback, useContext, useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import superagent from 'superagent';
import { AuthContext } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import useFeatureFlags from '../hooks/useFeatureFlags';

const MyNavbar: React.FC = () => {
  const { user, setUser } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const flags = useFeatureFlags();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('googleToken'); // Clear the stored token
    setUser(null);
    navigate('/');
  }, [setUser, navigate]);

  useEffect(() => {
    const token = localStorage.getItem('googleToken');
    if (token) {
      superagent
        .get('https://www.googleapis.com/oauth2/v3/userinfo')
        .set('Authorization', `Bearer ${token}`)
        .then((response) => {
          setUser(response.body);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
          handleLogout();
        });
    }
  }, [setUser, handleLogout]);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // Step 1: Store the access token in local storage
      localStorage.setItem('googleToken', tokenResponse.access_token);

      try {
        const userInfoResponse = await superagent
          .get('https://www.googleapis.com/oauth2/v3/userinfo')
          .set('Authorization', `Bearer ${tokenResponse.access_token}`);

        const userInfo = userInfoResponse.body;
        setUser(userInfo);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    onError: (errorResponse) => {
      console.error('Login Failed:', errorResponse);
    },
    scope: 'profile email',
  });

  return (
    <Navbar className="navbar" bg="light" expand="lg">
      <Container>
        {/* <Navbar.Brand href="#home">My Site</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto flex-grow-1">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            {flags.upgradeEnabled && (
              <>
                <Nav.Link as={Link} to="/upgrade">
                  Upgrade
                </Nav.Link>
              </>
            )}
            {user ? (
              <>
                <Nav.Link as={Link} to="/dashboard">
                  Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/leaderboard">
                  Leaderboard
                </Nav.Link>
              </>
            ) : null}
            {/* {user ? (
              <Nav.Link as={Link} to="/games">
                Games
              </Nav.Link>
            ) : null} */}
          </Nav>

          <Nav>
            {user ? (
              <>
                <Navbar.Text className="me-2">👋 {user.name}</Navbar.Text>
                {/* <Navbar.Text className="me-2">
                  Signed in as: {user.email}
                </Navbar.Text> */}
                <Nav.Link onClick={handleLogout} style={{ cursor: 'pointer' }}>
                  Sign Out
                </Nav.Link>
              </>
            ) : (
              <Nav.Link onClick={() => login()} style={{ cursor: 'pointer' }}>
                Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
