import { config } from './config';
import { FeatureFlag, Game, Submission } from './types';
import request from 'superagent';

const ns = '@api';

const getToken = () => {
  return localStorage.getItem('googleToken'); // Replace with your actual token key
};

const isTest = config.isTest;
const host = config.api.host;
const serverlessHost = config.serverless.host;

export const saveGameResults = async (payload: Submission) => {
  const url = `${host}/api/v0/submissions`;
  const logCtx = `${ns}.saveGameResults`;
  const token = getToken();
  // console.log(logCtx, { token });
  if (isTest) {
    console.log(logCtx, 'test environment... not posting');
    return;
  }

  await request
    .post(url)
    .set({
      ...(token != null && { Authorization: `Bearer ${token}` }),
    })
    .send(payload);
  console.log(logCtx, 'game results saved!');
};

export const getMe = async () => {
  const logCtx = `${ns}.getMe`;
  const token = getToken();
  console.log(logCtx, { token });
  if (!token) {
    return null;
  }
  const response = await request
    .get(`${host}/me`)
    .set('Authorization', `Bearer ${token}`);
  console.log(logCtx, 'response:', response.body.data);
  return response.body.data;
};

export const fetchFeatureFlags = async (): Promise<FeatureFlag[]> => {
  const token = getToken();
  const response = await request
    .get(`${host}/feature_flags`)
    .set('Authorization', `Bearer ${token}`);
  return response.body.data.featureFlags;
};

export const listGames = async (): Promise<Game[]> => {
  const token = getToken();
  const response = await request
    .get(`${host}/api/v0/games`)
    .set('Authorization', `Bearer ${token}`);
  return response.body.data.games!;
};

export const createGame = async (values): Promise<Game> => {
  const token = getToken();
  const res = await request
    .post(`${host}/api/v0/games`)
    .set('Authorization', `Bearer ${token}`)
    .send(values);
  return res.body.data.game;
};

export const listSubmissions = async (): Promise<Submission[]> => {
  const token = getToken();
  const response = await request
    .get(`${host}/api/v0/submissions`)
    .set('Authorization', `Bearer ${token}`);
  return response.body.data.submissions!;
};
