import React from 'react';
import { Field } from 'formik';

type PlayerRowProps = {
  index: number;
  arrayHelpers: {
    remove(index: number): any;
  };
};

const PlayerRow = ({ index, arrayHelpers }: PlayerRowProps) => (
  <div className="row align-items-center mt-3 mb-3 text-center" key={index}>
    <div className="col-4 p-1">
      {/* <div className="form-group"> */}
      <Field
        as="input"
        className="field form-control"
        type="text"
        name={`players.${index}.name`}
        placeholder="Player"
        required
      />
      {/* </div> */}
    </div>
    <div className="col p-1">
      {/* <div className="form-group"> */}
      <Field
        as="input"
        className="field form-control"
        type="number"
        label="Buy In"
        name={`players.${index}.buyIn`}
        placeholder="Starting"
        min={0}
        required
      />
      {/* </div> */}
    </div>

    <div className="col p-1">
      {/* <div className="form-group"> */}
      <Field
        className="field form-control"
        type="number"
        label="Ending Cash"
        as="input"
        name={`players.${index}.amount`}
        placeholder="Ending"
        min={0}
        required
      />
      {/* </div> */}
    </div>

    <div className="col-auto p-0">
      {/* <div className="form-group"> */}
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => arrayHelpers.remove(index)}
      >
        Delete
      </button>
      {/* </div> */}
    </div>
  </div>
);

export default PlayerRow;
