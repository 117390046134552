export const formatCurrency = (amount: number | undefined) => {
  if (!amount) return '$0';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
};

export const getEnumDisplay = (
  en: Record<
    PropertyKey,
    {
      ordinal: number;
      display: string;
    }
  >,
  ordinal: number
) => {
  const enumValues = Object.values(en);
  const enumValue = enumValues.find((value) => value.ordinal === ordinal);
  return enumValue?.display!;
};
