export const config = {
  appVersion: process.env.REACT_APP_VERSION!,
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  },
  isTest: process.env.NODE_ENV === 'development',
  api: {
    host: process.env.REACT_APP_API_HOST!,
  },
  serverless: {
    host: process.env.REACT_APP_SERVERLESS_HOST!,
  },
  saveEnabled: process.env.REACT_APP_SAVE_ENABLED === 'true',
  serviceWorkerEnabled: false,
  webVitalsEnabled: false,
  defaultFlags: {
    debug: false,
    upgradeEnabled: false,
  },
} as const;

console.log({ config });
