export const en = {
  HOME: {
    ordinal: 0,
    display: 'Home',
  },
  CASINO: {
    ordinal: 1,
    display: 'Casino',
  },
  ONLINE: {
    ordinal: 2,
    display: 'Online',
  },
  TOURNAMENT: {
    ordinal: 3,
    display: 'Tournament',
  },
  OTHER: {
    ordinal: 4,
    display: 'Other',
  },
} as const;

export enum GameType {
  HOME = 0,
  CASINO = 1,
  ONLINE = 2,
  TOURNAMENT = 3,
  OTHER = 4,
}
