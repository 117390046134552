import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { getMe } from '../api';

export const Debug = () => {
  const { user } = useContext(AuthContext)!;
  const handleUser = async () => {
    // console.log('User:', user);
    await getMe().catch((e) => console.log(e));
  };
  return (
    <div>
      <button onClick={handleUser}>Debug</button>
    </div>
  );
};

export default Debug;
