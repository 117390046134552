import React, { useContext } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import './Upgrade.css';
import UpgradeButton from '../common/UpgradeButton';
import { AuthContext } from '../AuthContext';

const UpgradePage = () => {
  const { user } = useContext(AuthContext)!;

  return (
    <Container className="upgrade-container mt-5 mb-5">
      <header className="text-center">
        <h1 className="display-4">Unlock Premium Features!</h1>
        <p className="lead">
          Join our community for just <strong>$10 a month</strong> and enjoy
          exclusive features.
        </p>
        {/* <Button
          variant="primary"
          size="lg"
          onClick={() => navigate('/checkout')}
        >
          Upgrade Now
        </Button> */}
        <UpgradeButton user={user} />
      </header>

      <section className="features mt-5">
        <h2 className="text-center">Premium Features</h2>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} md={4}>
            <Card className="feature-card mb-4">
              <Card.Body className="d-flex flex-column">
                <Card.Title>🗓️ Scheduling Games</Card.Title>
                <Card.Text>
                  Effortlessly schedule your poker nights with our integrated
                  calendar.
                </Card.Text>
                {/* <Button variant="link" className="mt-auto">
                  Learn More
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Card className="feature-card mb-4">
              <Card.Body className="d-flex flex-column">
                <Card.Title>📩 Invite Friends</Card.Title>
                <Card.Text>
                  Send invitations to your friends to join in on the fun.
                </Card.Text>
                {/* <Button variant="link" className="mt-auto">
                  Learn More
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Card className="feature-card mb-4">
              <Card.Body className="d-flex flex-column">
                <Card.Title>📊 Analytics</Card.Title>
                <Card.Text>
                  Access detailed analytics on your game history and
                  performance.
                </Card.Text>
                {/* <Button variant="link" className="mt-auto">
                  Learn More
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Card className="feature-card mb-4">
              <Card.Body className="d-flex flex-column">
                <Card.Title>🏆 Leaderboards</Card.Title>
                <Card.Text>
                  Compete with friends and see who’s on top!
                </Card.Text>
                {/* <Button variant="link" className="mt-auto">
                  Learn More
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Card className="feature-card mb-4">
              <Card.Body className="d-flex flex-column">
                <Card.Title>💬 Social Interactions</Card.Title>
                <Card.Text>
                  Chat with fellow players and share your experiences.
                </Card.Text>
                {/* <Button variant="link" className="mt-auto">
                  Learn More
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {/* <section className="pricing text-center mt-5">
        <h2>Subscription Details</h2>
        <p>
          Only <strong>$10 a month!</strong>
        </p>
        <Button variant="success" size="lg">
          Start Your Free Trial
        </Button>
      </section> */}
      <section className="subscription-details text-center mt-5">
        <h2>Subscription Details</h2>
        <p>
          Only <strong>$10 a month!</strong>
        </p>
        <p>Get access to all premium features immediately upon upgrade!</p>
        <p>
          No hidden fees or extra costs. Just a simple $10 monthly subscription!
        </p>
        <p>Sign up today and get your first month free!</p>
        <UpgradeButton user={user} text="Start Your Free Trial" />
      </section>

      <section className="testimonials text-center mt-5">
        <h2>What Our Users Say</h2>
        <div className="testimonial-card">
          <blockquote className="blockquote">
            <p>
              "Upgrading to premium has completely transformed my poker
              experience! The scheduling feature makes it so easy to set up
              games with friends, and the analytics have helped me improve my
              skills dramatically. Highly recommend!"
            </p>
            <footer className="blockquote-footer">
              John D., Enthusiastic Player
            </footer>
          </blockquote>
        </div>

        <div className="testimonial-card">
          <blockquote className="blockquote">
            <p>
              "I love the community features! Being able to invite friends and
              chat with fellow players has made my poker nights way more
              enjoyable. Plus, the leaderboards keep things competitive and
              fun!"
            </p>
            <footer className="blockquote-footer">
              Emily R., Social Gamer
            </footer>
          </blockquote>
        </div>

        <div className="testimonial-card">
          <blockquote className="blockquote">
            <p>
              "The exclusive tournaments are a game-changer! It feels great to
              compete for prizes, and the analytics feature gives me insights
              that I never had before. This membership is worth every penny!"
            </p>
            <footer className="blockquote-footer">
              Michael T., Competitive Player
            </footer>
          </blockquote>
        </div>

        <div className="testimonial-card">
          <blockquote className="blockquote">
            <p>
              "I was hesitant to upgrade, but now I can't imagine playing
              without the premium features. The user-friendly interface for
              scheduling games is fantastic, and I love being part of a
              community that shares my passion!"
            </p>
            <footer className="blockquote-footer">
              Sarah K., Casual Player
            </footer>
          </blockquote>
        </div>

        <div className="testimonial-card">
          <blockquote className="blockquote">
            <p>
              "The monthly subscription is a steal! The premium features have
              enhanced my gameplay, and I’ve met so many amazing people through
              the platform. I recommend it to everyone I know!"
            </p>
            <footer className="blockquote-footer">
              David S., Poker Enthusiast
            </footer>
          </blockquote>
        </div>
      </section>
    </Container>
  );
};

export default UpgradePage;
