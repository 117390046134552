import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import './Checkout.css';

const Checkout: React.FC = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form inputs
    if (!cardNumber || !expiryDate || !cvv) {
      setError('Please fill in all fields.');
      return;
    }

    // Call your payment processing logic here (e.g., using Stripe)
    try {
      await processPayment({ cardNumber, expiryDate, cvv });
      setSuccess(true);
      setError('');
    } catch (err) {
      setError('Payment processing failed. Please try again.');
    }
  };

  return (
    <Container className="upgrade-container mb-5">
      <h2 className="text-center">Checkout</h2>

      {/* Subscription Details Section */}
      <section className="subscription-details text-center mt-4">
        <h3>Subscription Overview</h3>
        <p>
          Join now for only <strong>$10 per month!</strong>
        </p>
        <ul className="feature-list">
          <li>🎮 Scheduling Games</li>
          <li>📩 Invite Friends</li>
          <li>📊 Detailed Analytics</li>
          <li>🏆 Exclusive Tournaments</li>
          <li>💬 Social Interactions</li>
        </ul>
        <p>Enjoy a 14-day money-back guarantee. Cancel anytime!</p>
      </section>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Subscription successful!</Alert>}

      <Form onSubmit={handleSubmit} className="mt-4">
        <Form.Group controlId="cardNumber">
          <Form.Label>Card Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter card number"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="expiryDate">
          <Form.Label>Expiry Date</Form.Label>
          <Form.Control
            type="text"
            placeholder="MM/YY"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="cvv">
          <Form.Label>CVV</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter CVV"
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
          />
        </Form.Group>

        <div className="">
          <Button variant="primary" type="submit" className="mt-3">
            Complete Upgrade
          </Button>
        </div>
      </Form>
    </Container>
  );
};

// Dummy function to simulate payment processing
const processPayment = async ({ cardNumber, expiryDate, cvv }) => {
  return new Promise((resolve) => setTimeout(resolve, 1000));
};

export default Checkout;
