import _ from 'lodash';

const ns = '@helpers';

export const potsMatch = (
  players: {
    buyIn: number;
    amount: number;
  }[]
) => {
  const totalBuyIn = _.sumBy(players, (x) => Number(x.buyIn));
  const totalPot = _.sumBy(players, (x) => Number(x.amount));
  return {
    result: totalBuyIn === totalPot,
    totalBuyIn,
    totalPot,
  };
};

export const calculate = (
  players: Array<{
    name: string;
    amount: number;
    buyIn: number;
  }>
) => {
  const logCtx = `${ns}.calculate`;
  const { result: doPotsMatch, totalBuyIn, totalPot } = potsMatch(players);
  if (!doPotsMatch) {
    throw new Error(
      `Invalid input. Buy In: ${totalBuyIn} Ending Pot: ${totalPot}`
    );
  }
  const cast = (x: any) => ({
    buyIn: Number(x.buyIn ?? 0),
    amount: Number(x.amount ?? 0),
    name: x.name,
  });

  const winners = _.chain(players)
    .mapValues(cast)
    .filter((x) => x.buyIn < x.amount)
    .map((x) => ({ ...x, change: x.amount - x.buyIn }))
    .sort((a, b) => a.change - b.change)
    .value();

  const losers = _.chain(players)
    .mapValues(cast)
    .filter((x) => x.buyIn >= x.amount)
    .map((x) => ({ ...x, change: x.amount - x.buyIn }))
    .sort((a, b) => b.change - a.change)
    .value();

  // console.debug({ losers, winners });
  //   console.log(logCtx, { totalPot });
  const payments: any[] = [];
  const loserQueue = [...losers];
  const winnerQueue = [...winners];
  let currentWinner: Player = winnerQueue.shift() as any;
  const payouts = new Map();
  type Player = (typeof losers)[0];
  while (loserQueue.length) {
    const loser: (typeof losers)[0] = loserQueue.shift() as any;
    const currentLoserName = loser.name;
    console.log(
      logCtx,
      `calculating payments for ${loser.name} amount lost: ${loser.change}`
    );
    /**
     * amount the loser lost
     */
    let loserAmountOwed = Math.abs(loser.change);
    console.log(logCtx, loser.name, { loserAmountOwed });
    while (loserAmountOwed !== 0) {
      if (!payouts.has(currentWinner)) {
        payouts.set(currentWinner, 0);
      }
      // total amount the winner needs to receive to be made whole
      const amountOwedRemaining = Math.abs(
        Math.abs(currentWinner.change) - payouts.get(currentWinner)
      );
      console.log(logCtx, { name: currentWinner.name, amountOwedRemaining });
      //   const amountToPay = Math.max(amountOwed, loserAmountOwed);
      //   console.log(logCtx, {amountToPay});
      if (loserAmountOwed <= amountOwedRemaining) {
        console.log(
          logCtx,
          `loser ${loser.name} owes more than ${currentWinner.name} gets back. partially paying ${currentWinner.name}`
        );
        payouts.set(
          currentWinner,
          payouts.get(currentWinner) + loserAmountOwed
        );
        payments.push(
          `${loser.name} pays ${currentWinner.name} ${Math.abs(
            loserAmountOwed
          )}`
        );
        loserAmountOwed = 0;
      } else {
        payouts.set(
          currentWinner,
          payouts.get(currentWinner) + amountOwedRemaining
        );
        payments.push(
          `${loser.name} pays ${currentWinner.name} ${Math.abs(
            amountOwedRemaining
          )}`
        );
        loserAmountOwed = loserAmountOwed - amountOwedRemaining;
      }

      if (payouts.get(currentWinner) === Math.abs(currentWinner.change)) {
        console.log(logCtx, `${currentWinner.name} is paid in full.`);
        currentWinner = winnerQueue.shift() as any;
      }
    }
    // const;
  }

  return payments;
};

export const calculateTotalBuyIn = (
  players: Array<{
    buyIn?: number;
  }>
) => {
  return _.sumBy(players, (x) => Number(x.buyIn ?? 0));
};
export const calculateTotalFinalPot = (
  players: Array<{
    amount?: number;
  }>
) => {
  return _.sumBy(players, (x) => Number(x.amount ?? 0));
};
