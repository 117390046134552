import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <div className="container mt-4">
        <h2 className="my-4">Privacy Policy</h2>
        <div className="container">
          <h3>Wilder Innovation LLC</h3>
          <p>
            <i>Last Updated: May 13, 2023</i>
          </p>
          <p>
            Wilder Innovation LLC (“We”, “Us”, “Our”, or "Wilder Innovation")
            recognizes the importance of privacy. We have developed this Privacy
            Policy to inform you about how we collect, use, disclose, and
            safeguard your personal information when you use our services and
            our website.
          </p>
          <p>
            Please read this Privacy Policy carefully. If you do not agree with
            the terms of this Privacy Policy, please do not access or use our
            services.
          </p>
          <h4 className="my-4">1. COLLECTION OF YOUR INFORMATION</h4>
          <p>
            We may collect personal information in a variety of ways, including,
            but not limited to, when you visit our site, register on the site,
            place an order, subscribe to the newsletter, and in connection with
            other activities, services, features or resources we make available
            on our site.
          </p>
          <h4 className="my-4">1.1 Personal Data</h4>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
          </ul>

          <h4 className="my-4">1.2 Usage Data</h4>
          <p>
            We may also collect information on how the Service is accessed and
            used ("Usage Data"). This Usage Data may include information such as
            your computer's Internet Protocol address (e.g., IP address),
            browser type, browser version, the pages of our Service that you
            visit, the time and date of your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data.
          </p>

          <h4 className="my-4">1.3 Tracking &amp; Cookies Data</h4>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags, and scripts to collect and track
            information and to improve and analyze our Service.
          </p>

          <h4 className="my-4">2. USE OF YOUR INFORMATION</h4>
          <p>
            We may use the information we collect from you in various ways,
            including to:
          </p>
          <ul>
            <li>Provide, maintain and improve our Service</li>
            <li>Notify you about changes to our Service</li>
            <li>
              Allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>Provide customer support</li>
            <li>
              Gather analysis or valuable information so that we can improve our
              Service
            </li>
            <li>Monitor the usage of our Service</li>
            <li>Detect, prevent and address technical issues</li>
          </ul>

          <h4 className="my-4">3. DISCLOSURE OF YOUR INFORMATION</h4>
          <p>
            We do not sell, trade, or rent users' personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above.
          </p>

          <h4 className="my-4">4. SECURITY OF YOUR INFORMATION</h4>
          <p>
            We are committed to protecting your personal information and have
            implemented suitable security measures to protect the information
            from unauthorized access, alteration, disclosure, or destruction.
            However, please be aware that no security measures are perfect or
            impenetrable and no method of data transmission can be guaranteed
            against any interception or other types of misuse.
          </p>

          <h4 className="my-4">5. CHANGES TO THIS PRIVACY POLICY</h4>
          <p>
            We may update this Privacy Policy from time to time. You are advised
            to review this page periodically for any changes. Changes to this
            Privacy Policy are effective when they are posted on this page.
          </p>

          <h4 className="my-4">6. CONTACT US</h4>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:{' '}
            <a href="mailto:team@wilderinnovation.com">
              team@wilderinnovation.com
            </a>
          </p>

          <strong>
            By using our site or services, you consent to this Privacy Policy.
          </strong>
          <p>
            This Privacy Policy is intended to comply with the laws and
            regulations in the jurisdiction in which we operate. If you are
            unsure whether this Privacy Policy is in conflict with the
            applicable local rules where you are located, you should not submit
            your personal information. If you are located within the European
            Union, you should note that your information will be transferred to
            the United States, which is deemed by the European Union to have
            inadequate data protection. Nevertheless, in accordance with local
            laws implementing European Union Directive 95/46/EC of 24 October
            1995 ("EU Privacy Directive") on the protection of individuals with
            regard to the processing of personal data and on the free movement
            of such data, individuals located in countries outside of the United
            States of America who submit personal information do thereby consent
            to the general use of such information as provided in this Privacy
            Policy and to its transfer to and/or storage in the United States of
            America.
          </p>
          <p>
            If you do not consent to the terms of this Privacy Policy, please do
            not use our services. Please contact us if you have any questions
            about our Privacy Policy or if you wish to review, modify or delete
            your personal information.
          </p>

          <h4 className="my-4">7. YOUR RIGHTS</h4>
          <p>
            Depending on the law of your country, you may have the right to
            request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request to:{' '}
            <a href="mailto:team@wilderinnovation.com">
              team@wilderinnovation.com
            </a>
            .
          </p>

          <h4 className="my-4">8. CALIFORNIA PRIVACY RIGHTS</h4>
          <p>
            California residents under the age of 18 who are registered users of
            online sites, services, or applications have the right to request
            and obtain removal of content or information they have publicly
            posted. To make such a request, please send an email with a detailed
            description of the specific content or information to{' '}
            <a href="mailto:team@wilderinnovation.com">
              team@wilderinnovation.com
            </a>
            .
          </p>

          <h4 className="my-4">9. CHILDREN'S PRIVACY</h4>
          <p>
            Our Service does not address anyone under the age of 13
            ("Children"). We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If you are a parent or
            guardian and you are aware that your Child has provided us with
            Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
          <h4 className="my-4">10. GDPR (EU USERS)</h4>
          <p>
            We adhere to the principles set out by the General Data Protection
            Regulation (GDPR) for users based in the EU. This includes the
            rights of access, rectification, erasure, restriction, portability,
            and objection. For any requests related to your rights under the
            GDPR, please contact us at{' '}
            <a href="mailto:team@wilderinnovation.com">
              team@wilderinnovation.com
            </a>
            .
          </p>
          <p className="mt-4">
            This Privacy Policy was last updated on the date indicated above.
            Your continued use of the Service after any changes or revisions to
            this Privacy Policy shall indicate your agreement with the terms of
            such a revised Privacy Policy.
          </p>
          <p>
            <strong>
              By using our site or services, you consent to this Privacy Policy.
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
