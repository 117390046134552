import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

type Props = {
  user?: any;
  text?: string;
};

const UpgradeButton: React.FC<Props> = ({ user, text }) => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const googleLogin: any = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await login(tokenResponse); // Handle your login logic here
      navigate('/checkout'); // Redirect to checkout page after successful login
    },
    onError: (errorResponse) => {
      console.error('Login Failed:', errorResponse);
    },
    scope: 'profile email',
  });

  return (
    <button
      onClick={() => {
        if (user) {
          navigate('/checkout');
          return;
        }
        googleLogin();
      }}
      className="btn btn-success"
    >
      {text ? text : 'Upgrade to Premium'}
    </button>
  );
};

export default UpgradeButton;
