/* eslint-disable chai-friendly/no-unused-expressions */
import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { GameType, en as gameTypes } from './enums/gameType';

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  buyIn: Yup.number()
    .required('Buy-in is required')
    .positive('Buy-in must be positive'),
  cashOut: Yup.number().required('Cash-out is required'),
  gameType: Yup.number().required('Game type is required'),
});

const GameForm = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{
        // description: 'Foo',
        // buyIn: 1,
        // cashOut: 1,
        description: '',
        buyIn: '',
        cashOut: '',
        gameType: GameType.CASINO,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <FormikForm id="gameForm">
          <Form.Group className="my-2" controlId="gameType">
            <Form.Label>Game Type</Form.Label>

            <Field
              name="gameType"
              as="select"
              className={`form-control ${
                touched.gameType && errors.gameType ? 'is-invalid' : ''
              }`}
              component={({ field }) => (
                <Form.Select value={field.value}>
                  {Object.values(gameTypes).map((type) => (
                    <option key={type.ordinal} value={type.ordinal}>
                      {type.display}
                    </option>
                  ))}
                </Form.Select>
              )}
            />
            {touched.gameType && errors.gameType ? (
              <div className="invalid-feedback">{errors.gameType}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="my-2" controlId="description">
            <Form.Label>Description (Optional)</Form.Label>
            <Field
              name="description"
              type="text"
              className={`form-control ${
                touched.description && errors.description ? 'is-invalid' : ''
              }`}
            />
            {touched.description && errors.description ? (
              <div className="invalid-feedback">{errors.description}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="my-2" controlId="buyIn">
            <Form.Label>Buy-In</Form.Label>
            <Field
              name="buyIn"
              type="number"
              className={`form-control ${
                touched.buyIn && errors.buyIn ? 'is-invalid' : ''
              }`}
            />
            {touched.buyIn && errors.buyIn ? (
              <div className="invalid-feedback">{errors.buyIn}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="my-2" controlId="cashOut">
            <Form.Label>Cash-Out</Form.Label>
            <Field
              name="cashOut"
              type="number"
              className={`form-control ${
                touched.cashOut && errors.cashOut ? 'is-invalid' : ''
              }`}
            />
            {touched.cashOut && errors.cashOut ? (
              <div className="invalid-feedback">{errors.cashOut}</div>
            ) : null}
          </Form.Group>
        </FormikForm>
      )}
    </Formik>
  );
};

export default GameForm;
