import React, { useRef, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import GameForm from '../GameForm';
import { createGame, listGames, listSubmissions } from '../api';
import { useQuery } from '@tanstack/react-query';
import Tile from '../common/Tile';
import { formatCurrency, formatDate, getEnumDisplay } from '../utils';
import { en as gameType } from '../enums/gameType';

// import Modal from '../common/Modal';

const Dashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    setIsSubmitting(true);
    console.log(values);
    createGame(values)
      .then(() => {
        setSubmitting(false);
        setIsSubmitting(false);
        closeModal();
      })
      .catch((error) => {
        console.error('Error creating game:', error);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  const {
    data: games = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['games'],
    queryFn: listGames,
  });

  const {
    data: submissions = [],
    isLoading: submissionsIsLoading,
    error: submissionsError,
  } = useQuery({
    queryKey: ['submissions'],
    queryFn: listSubmissions,
  });

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  const totalProfit = games.reduce((acc, game) => acc + game.profit, 0);
  const profitClass = totalProfit >= 0 ? 'bg-light-green' : 'bg-light-red';

  return (
    <>
      <div className="container my-4 text-center">
        <h2 className="my-4">Dashboard</h2>
        <p>
          Use this section to track your all-time poker results. You can log
          your games and see your total profit over time. We'll also show you a
          breakdown of your games by type.
        </p>
        <div className="my-4">
          <button
            className="btn btn-primary bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded"
            onClick={openModal}
          >
            Log Game
          </button>
        </div>
        <Tile>
          <strong># Games:</strong> {games.length}
        </Tile>

        <Tile>
          <strong># Home Game Submissions:</strong> {submissions.length}
        </Tile>

        <Tile className={profitClass}>
          <strong>Total Profit:</strong> {formatCurrency(totalProfit)}
        </Tile>

        <Modal show={isModalOpen} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Log a Game</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GameForm onSubmit={handleSubmit} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="gameForm"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <h3 className="my-4">Historical Games</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            {games.map((game, index) => (
              <tr key={index}>
                <td>{formatDate(game.createdAt)}</td>
                <td>{getEnumDisplay(gameType, game.gameType)}</td>
                <td>{game.description || 'n/a'}</td>
                <td>{formatCurrency(game.profit)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Dashboard;
