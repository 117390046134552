import { useQuery } from '@tanstack/react-query';
import { FeatureFlag } from '../types';
import { fetchFeatureFlags } from '../api';
import { config } from '../config';

const useFeatureFlags = () => {
  const { data, isLoading, error } = useQuery<FeatureFlag[]>({
    queryKey: ['feature_flags'],
    queryFn: fetchFeatureFlags,
  });

  if (isLoading) {
    return { ...config.defaultFlags };
  }

  if (error) {
    return { ...config.defaultFlags };
  }

  const flags = data!.reduce((acc, flag) => {
    acc[flag.name] = flag.enabled;
    return acc;
  }, {} as Record<PropertyKey, boolean>);
  return flags;
};

export default useFeatureFlags;
