import React from 'react';

const Tip = () => (
  <div className="Tip">
    <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="business" value="3ARHVFH4LANBY" />
      <input type="hidden" name="no_recurring" value="0" />
      <input type="hidden" name="currency_code" value="USD" />
      <input
        type="submit"
        className="btn btn-sm btn-success"
        name="submit"
        title="Tip The Dealer"
        value="Tip The Dealer"
        alt="Donate with PayPal button"
      />
    </form>
  </div>
);

export default Tip;
