import React from 'react';
import './Footer.css';
import { config } from '../config';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const { appVersion } = config;
  return (
    <footer className="footer">
      <section style={{ paddingBottom: '10px' }}>
        Made with ❤️&nbsp; in Los Angeles
        <br />© 2023 Wilder Innovation Labs. All Rights Reserved.
        <br />
        <Nav.Link as={Link} to="/privacy_policy">
          Privacy Policy
        </Nav.Link>
        <>v{appVersion}</>
      </section>
    </footer>
  );
};

export default Footer;
