import React, { useRef, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import GameForm from '../GameForm';
import { createGame, listGames, listSubmissions } from '../api';
import { useQuery } from '@tanstack/react-query';
import Tile from '../common/Tile';
import { formatCurrency, formatDate, getEnumDisplay } from '../utils';
import { en as gameType } from '../enums/gameType';

// import Modal from '../common/Modal';

const Leaderboard: React.FC = () => {
  const {
    data: submissions = [],
    isLoading: submissionsIsLoading,
    error: submissionsError,
  } = useQuery({
    queryKey: ['submissions'],
    queryFn: listSubmissions,
  });

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  //   const totalProfit = games.reduce((acc, game) => acc + game.profit, 0);
  //   const profitClass = totalProfit >= 0 ? 'bg-light-green' : 'bg-light-red';
  const players = new Map<
    string,
    {
      totalProfit: number;
      games: number;
      name: string;
    }
  >();

  submissions.forEach((submission) => {
    submission.players.forEach((player) => {
      if (!players.has(player.name)) {
        players.set(player.name, {
          totalProfit: 0,
          games: 0,
          name: player.name,
        });
      }
      const playerStats = players.get(player.name)!;
      const gameProfit = player.amount - player.buyIn;
      playerStats.totalProfit += gameProfit;
      playerStats.games++;
    });
  });

  const rows = Array.from(players.values()).sort((a, b) => {
    return b.totalProfit - a.totalProfit;
  });

  return (
    <>
      <div className="container my-4 text-center">
        <h2 className="my-4">Leaderboard</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th># Games</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((player) => (
              <tr>
                <td>{player.name}</td>
                <td>{player.games}</td>
                <td>{formatCurrency(player.totalProfit)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Leaderboard;
