import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './layout/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Dashboard from './pages/Dashboard';
import NotFound from './NotFound';
import Footer from './layout/Footer';
import Upgrade from './pages/Upgrade';
import Checkout from './pages/Checkout';
import Leaderboard from './pages/Leaderboard';

const App: React.FC = () => {
  // const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  // // Initialize deferredPrompt for use later to show browser install prompt.
  // const [showInstallPWA, setShowInstallPWA] = useState<boolean>(false);

  // useEffect(() => {
  //   const handleBeforeInstallPrompt = (e: Event) => {
  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();
  //     // Save the event so it can be triggered later.
  //     setDeferredPrompt(e);
  //     // Update UI to notify the user they can install the PWA
  //     setShowInstallPWA(true);
  //   };

  //   window.addEventListener(
  //     'beforeinstallprompt',
  //     handleBeforeInstallPrompt as any
  //   );

  //   return () => {
  //     window.removeEventListener(
  //       'beforeinstallprompt',
  //       handleBeforeInstallPrompt as any
  //     );
  //   };
  // }, []);

  // const handleInstallClick = async () => {
  //   // Hide the app-provided install promotion
  //   setShowInstallPWA(false);
  //   // Show the install prompt
  //   (deferredPrompt as any).prompt();
  //   // Wait for the user to respond to the prompt
  //   const { outcome } = await (deferredPrompt as any).userChoice;
  //   // Optionally, send analytics event with outcome of user choice
  //   console.log(`User response to the install prompt: ${outcome}`);
  //   // We've used the prompt, and can't use it again, reset the deferredPrompt
  //   setDeferredPrompt(null);
  // };

  return (
    <Router>
      <Navbar />
      {/* {showInstallPWA && deferredPrompt && (
        <div className="container mt-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleInstallClick}
          >
            Install App
          </button>
        </div>
      )} */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/upgrade" element={<Upgrade />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
