import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Tile = ({ children, className }: Props) => {
  return (
    <div
      className={classNames(
        'w-100 h-100 border border-dark p-3 d-flex justify-content-center align-items-center flex-column font-weight-bold h5',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Tile;
