import React, { useEffect, useState } from 'react';
import logo from '../images/red-chip-clear.png';
import Tip from '../Tip';
import './Hero.css';
import Debug from './Debug';

export type HeroProps = {
  showTip: boolean;
  showDebug?: boolean;
};

const Hero = ({ showTip = true, showDebug = false }: HeroProps) => {
  return (
    <header className="hero px-5">
      <img src={logo} className="App-logo" alt="logo" />
      <div
        style={{
          paddingTop: '50px',
        }}
      >
        <h2>Poker Payout Calculator</h2>
        <span
          style={{
            fontSize: '.8em',
          }}
        >
          We created the Poker Game Payout Calculator because we needed a simple
          widget to calculate the payments that needed to occur at the end of
          our home poker games. Simply enter the player's name, total buy-in
          amount, and the player's closing balance and we'll calculate the
          simplest way to make everybody whole.
        </span>
      </div>
      <div
        className="row button-box"
        style={{
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <div className="" style={{ marginRight: '10px' }}>
          {showTip && <Tip />}
          {showDebug && <Debug />}
        </div>
      </div>
    </header>
  );
};

export default Hero;
