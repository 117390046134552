import React, { useState } from 'react';
import '../App.css';
import Calculator from '../Calculator';
import Hero from '../common/Hero';
import useFeatureFlags from '../hooks/useFeatureFlags';

const Home: React.FC = () => {
  const flags = useFeatureFlags();
  return (
    <div className="App">
      <Hero showTip={true} showDebug={flags.debug ?? false} />
      <div className="container">
        <section className="row">
          <div className="col-lg-2 col-xl-2"></div>
          <div
            className="col-lg-8 col-xl-8"
            style={{
              marginTop: '20px',
            }}
          >
            <Calculator />
          </div>
          <div className="col-lg-2 col-xl-2"></div>
        </section>
      </div>
    </div>
  );
};

export default Home;
