import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import {
  calculate,
  potsMatch,
  calculateTotalBuyIn,
  calculateTotalFinalPot,
} from './helpers';
import './Calculator.css';
import _ from 'lodash';
import PlayerRow from './PlayerRow';
import { Toast } from 'react-bootstrap';
import logo from './images/red-chip-clear.png';
import type { PlayerInput, Submission } from './types';
import { config } from './config';
import { saveGameResults } from './api';

const isTest = config.isTest;

const newPlayer = {
  name: '',
  // buyIn: '',
  // amount: '',
} as any as PlayerInput;

const preFill: Submission = {
  players: [
    {
      name: 'Ben',
      buyIn: 100,
      amount: 0,
    },
    {
      name: 'Max',
      buyIn: 100,
      amount: 150,
    },
    {
      name: 'Andrew',
      buyIn: 100,
      amount: 150,
    },
  ],
};

const ns = '@Calculator';

const Calculator = () => {
  const [payouts, setPayouts] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [toastBody, setToastBody] = useState('');
  return (
    <div className="Calculator">
      <h2>Players</h2>
      <br />
      <div>
        <Formik<Submission>
          initialValues={{
            players: [{ ...newPlayer }],
            ...(isTest ? preFill : {}),
          }}
          onSubmit={(values: Submission) => {
            const { players } = values;
            if (players.length < 2) {
              setToastBody('Must have more than 1 player!');
              setShow(true);
              return;
            }

            if (
              _.chain(players)
                .map((x) => x.name)
                .groupBy((x) => x)
                .mapValues((x) => x.length)
                .values()
                .some((x) => x > 1)
                .value()
            ) {
              setToastBody('Player names should be unique!');
              setShow(true);
              return;
            }

            if (
              players.some((player) => {
                return (
                  player.buyIn == null ||
                  player.amount == null ||
                  !(
                    _.isFinite(Number(player.buyIn)) &&
                    _.isFinite(Number(player.amount))
                  )
                );
              })
            ) {
              setToastBody('Enter buy ins and cash outs for all players!');
              setShow(true);
              return;
            }
            const {
              result: doPotsMatch,
              totalBuyIn,
              totalPot,
            } = potsMatch(players);
            if (!doPotsMatch) {
              setToastBody(
                `Buy In: $${totalBuyIn} should match Ending Pot: $${totalPot}`
              );
              setShow(true);
              return;
            }

            setTimeout(() => {
              setPayouts(calculate(values.players));

              saveGameResults(values).catch((e) => {
                console.error('unable to save game results', e);
              });
            }, 200);
          }}
        >
          {({ values }) => {
            const logCtx = `${ns}.values`;
            // console.log(logCtx, values);
            const totalBuyIn = calculateTotalBuyIn(values.players);
            const totalFinalPot = calculateTotalFinalPot(values.players);
            return (
              <Form>
                <div>
                  <Toast
                    className="toast"
                    onClose={() => {
                      setToastBody('');
                      setShow(false);
                    }}
                    show={show}
                    delay={3000}
                    autohide
                  >
                    <Toast.Header>
                      <img
                        className="spin"
                        width="20"
                        height="20"
                        src={logo}
                        alt=""
                      />
                      <strong className="mr-auto">&nbsp;Poker Payout</strong>
                    </Toast.Header>
                    <Toast.Body style={{}}>{toastBody}</Toast.Body>
                  </Toast>
                </div>
                <FieldArray
                  name="players"
                  render={(arrayHelpers) => (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          marginBottom: '20px',
                          marginRight: '10px',
                        }}
                        onClick={() =>
                          arrayHelpers.push({
                            ...newPlayer,
                          })
                        }
                      >
                        Add a Player
                      </button>

                      <button
                        className="btn btn-primary"
                        style={{
                          marginBottom: '20px',
                        }}
                        type="button"
                        onClick={() => setPayouts([])}
                      >
                        Clear Payouts
                      </button>
                      <br />
                      <div
                        style={{
                          textAlign: 'center',
                          padding: '10px',
                        }}
                      >
                        {values.players.map((player, i) => (
                          <PlayerRow
                            key={i}
                            index={i}
                            arrayHelpers={arrayHelpers}
                          />
                        ))}
                      </div>
                      <div>
                        <p>
                          Total Buy-In: ${totalBuyIn} • Total Pot: $
                          {totalFinalPot} • Diff: ${totalBuyIn - totalFinalPot}
                        </p>

                        <button
                          type="submit"
                          className="btn btn-primary submit-btn"
                        >
                          Calculate Payouts
                        </button>
                      </div>
                    </div>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      {payouts.length > 0 ? (
        <div
          style={{
            marginTop: '40px',
          }}
        >
          <h1>Payouts</h1>
          {payouts.map((payout, i) => {
            return (
              <div key={i}>
                {payout}
                <br />
              </div>
            );
          })}
        </div>
      ) : (
        <div>{/* <h1>No Payouts!</h1> */}</div>
      )}
    </div>
  );
};

export default Calculator;
